<template>
  <div id="ExtratoFinanceiro" class="container-fundo pa-0" v-bind:style="{ 'background-color':COR_PRINCIPAL }">
    <v-overlay :value="loading_relatorio">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <v-toolbar-title v-bind:style="{ 'background-color':COR_PRINCIPAL }" class="headline lighten-2">
    
      <!-- Título da página -->
      <div class="justify-space-between d-flex">
        <v-toolbar-title class="text-white title-page mt-2 ml-1 ">
          <router-link :to="{ name: 'PainelCliente' }" class="nav-link ml-2" aria-current="page">
            <v-btn icon dark color="grey" class="btn-icon mr-1">
              <v-icon color="#84A0CD" class="icon-action"> mdi-chevron-left </v-icon>
            </v-btn>
          </router-link>
            Extrato Financeiro
        </v-toolbar-title>

        <div v-if="isMobile">
          <v-btn
            x-small
            outlined
            color="white"
            class="mr-2 mt-1 mb-1"
            style="text-transform: capitalize;"
            @click="imprimirRelatorio(empreendimento)">
            Imprimir Extrato
          </v-btn>
        </div>
      </div>

        <!-- Botões de ação cabeçalho -->
        <v-toolbar-title
          class="mt-0 mb-6  ml-1 pa-0"
          dark
          elevation="0">
          <div class="d-flex justify-space-between" tabindex="-1">
            <v-select
              :items="lotes"
              v-model="empreendimento"
              item-value="cod_empreendvenda"
              item-text="empreend_nome"
              placeholder="Meu Lote"
              outlined
              @change="busca(1)"
              background-color="#FFFFFF"
              filled
              return-object
              required
              dense
              style="max-width:500px;">
              <template v-slot:selection="data">
                <v-list-item-content>
                  <v-list-item-title>{{ data.item.empreend_nome }}</v-list-item-title>
                  <v-list-item-subtitle >Unidade: {{ data.item.quadra }}/{{ data.item.lote }} | Contrato: {{ data.item.empreendvenda_contr_numero }} | Cod.: {{ data.item.cod_empreendvenda }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
              <template v-slot:item="data">
                <template>
                  <v-list-item-content>
                    <v-list-item-title>{{ data.item.empreend_nome }}</v-list-item-title>
                    <v-list-item-subtitle >Unidade: {{ data.item.quadra }}/{{ data.item.lote }} | Contrato: {{ data.item.empreendvenda_contr_numero }} | Cod.: {{ data.item.cod_empreendvenda }}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template>
            </v-select>
            <div v-if="!isMobile">
              <v-btn
                small
                outlined
                color="white"
                class="mr-5 mt-1 mb-1"
                style="text-transform: capitalize;"
                @click="imprimirRelatorio(empreendimento)">
                Imprimir Extrato
              </v-btn>
            </div>
          </div>
        </v-toolbar-title>
        <!-- Fim Botões de ação cabeçalho -->
      </v-toolbar-title>

      <v-row class="mt-n11 w-100 mx-0">
        <!-- Grade ----------------------------------->
        <v-col cols="12" class="pa-0 mx-0 w-100">
          <v-container class="container-principal container-rounded px-0 w-100 mx-0">

          <!-- Conteiner  -->
          <v-data-table
          id="dataTable"
            ref="dataTable"
            :headers="headers"
            no-sorting
            :loading="loading"
            fixed-header
            :height="tableHeight"
            hide-default-footer
            :hide-default-header="isMobile"
            loading-text="Carregando...  aguarde..."
            no-data-text="Nenhum Registro Encontrado. Use os campos acima para refinar sua consulta"
            no-results-text="Nenhum Registro Encontrado. Use os campos acima para refinar sua consulta`"
    				:items="cobrancas"
            style="white-space: nowrap"
            :items-per-page="1000">
            <template v-slot:item="{item}">
              <tr v-if="!isMobile">
                <td align="right">
                  {{ item.cod_empreendcobranca }}
                </td>

                <td align="center">
                  <span
                    v-if="item.empreendcobr_situacao == 'Aberto' && moment(item.empreendcobr_parc_dt).format() >= moment(moment(new Date()).format('YYYY-MM-DD')).format()"
                    class="status_aberto_vencer"
                  ><v-icon color="#FFFFFF" class="ml-n2" dense >mdi-check</v-icon>{{ item.empreendcobr_situacao }}
                  </span>

                  <span
                    v-if="item.empreendcobr_situacao == 'Aberto' && moment(item.empreendcobr_parc_dt).format() < moment(moment(new Date()).format('YYYY-MM-DD')).format()"
                    class="status_aberto_vencida"
                  ><v-icon color="#FFFFFF" class="ml-n2" dense >mdi-alert-octagon-outline</v-icon>{{ item.empreendcobr_situacao }}
                  </span>

                  <span
                    v-if="item.empreendcobr_situacao == 'Pago'"
                    class="status_pago"
                    >{{ item.empreendcobr_situacao }}
                  </span>

                  <span
                    v-if="item.empreendcobr_situacao == 'Rescindido'"
                    class="status_rescindido"
                    >{{ item.empreendcobr_situacao }}
                  </span>

                  <span
                    v-if="item.empreendcobr_situacao == 'Inativo'"
                    class="status_inativo"
                    >{{ item.empreendcobr_situacao }}
                  </span>
                </td>
              
                <td>{{ item.empreendcobr_descricao.substr(0, 4).initCap() }} ({{ item.empreendcobr_parcela_inicial }}/{{ item.empreendcobr_parcela_final }})</td>
                <td>{{ formatDate(item.empreendcobr_parc_dt) }}</td>
                <td align="center">{{ item.empreendcobr_parc_valor ? formatPrice(item.empreendcobr_parc_valor) : '' }}</td>
                <td>{{ item.empreendcobr_pagto_dt ? formatDate(item.empreendcobr_pagto_dt) : '' }}</td>

                <td align="right">
                    <v-tooltip top v-if=" item.empreendcobr_parc_acres_valor ||
                                          item.empreendcobr_parc_desc_valor ||
                                          item.empreendcobr_pagto_valor_multa ||
                                          item.empreendcobr_pagto_valor_juros
                                          ">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon small v-bind="attrs" v-on="on" color="red">mdi-cash</v-icon>
                      </template>
                      <span v-if="item.empreendcobr_parc_acres_valor"   align="right">{{ 'Acréscimo: ' + formatPrice(item.empreendcobr_parc_acres_valor)}}<br></span>
                      <span v-if="item.empreendcobr_parc_desc_valor"    align="right">{{ 'Desconto: ' + formatPrice(item.empreendcobr_parc_desc_valor)  }}<br></span>
                      <span v-if="item.empreendcobr_pagto_valor_multa"  align="right">{{ 'Multa: ' + formatPrice(item.empreendcobr_pagto_valor_multa)   }}<br></span>
                      <span v-if="item.empreendcobr_pagto_valor_juros"  align="right">{{ 'Juros: ' + formatPrice(item.empreendcobr_pagto_valor_juros)   }}<br></span>
                    </v-tooltip>
                    {{ item.empreendcobr_pagto_valor ? formatPrice(item.empreendcobr_pagto_valor) : '' }}
                  </td>

                <td style="width:280px;">
                  <span class="width-nome-customer">{{ item.empreend_venda.pessoa_venda.pessoa_nome }}</span>
                </td>

                <td>
                  <span class="width-empreendimento customer">{{ item.empreend_venda.vendas.empreend_nome }}</span>
                </td>

                <td style="width:280px; white-space: nowrap;">
                  <span class="width-adress">{{ item.empreend_venda.pessoa_venda.pessoa_enderecos[0].end_lograd + ", " + item.empreend_venda.pessoa_venda.pessoa_enderecos[0].end_num + ", " + item.empreend_venda.pessoa_venda.pessoa_enderecos[0].end_bairro }}</span>
                  <!-- {{ item.cep }} - {{ item.cidade }} -->
                </td>
              </tr>

              <!-- MOBILE ------------------------------------------------------------------------------- -->
              <tr v-if="isMobile">
                <td width="5%" class="pr-0">
                  <div>
                    <span
                      v-if="item.empreendcobr_situacao == 'Aberto' && moment(item.empreendcobr_parc_dt).format() >= moment(moment(new Date()).format('YYYY-MM-DD')).format()"
                      class="status_aberto_vencer"
                    ><v-icon color="#FFFFFF" class="ml-n2" dense >mdi-check</v-icon>{{ item.empreendcobr_situacao }}
                    </span>

                    <span
                      v-if="item.empreendcobr_situacao == 'Aberto' && moment(item.empreendcobr_parc_dt).format() < moment(moment(new Date()).format('YYYY-MM-DD')).format()"
                      class="status_aberto_vencida"
                    ><v-icon color="#FFFFFF" class="ml-n2" dense >mdi-alert-octagon-outline</v-icon>{{ item.empreendcobr_situacao }}
                    </span>

                    <span
                      v-if="item.empreendcobr_situacao == 'Pago'"
                      class="status_pago"
                      >{{ item.empreendcobr_situacao }}
                    </span>

                    <span
                      v-if="item.empreendcobr_situacao == 'Rescindido'"
                      class="status_rescindido"
                      >{{ item.empreendcobr_situacao }}
                    </span>

                    <span
                      v-if="item.empreendcobr_situacao == 'Inativo'"
                      class="status_inativo"
                      >{{ item.empreendcobr_situacao }}
                    </span>
                 </div>
                  <!-- <v-icon color="blue" class="mr-2 icon-menu">mdi-printer-outline</v-icon> -->
                </td>
                <td align="left" class="pl-1">
                  <strong style="font-size: 15px;" :style="{ 'color':COR_PRINCIPAL }">Parc ({{ item.empreendcobr_parcela_inicial }}/{{ item.empreendcobr_parcela_final }})</strong> &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;Cod. {{ item.cod_empreendcobranca }} 
                  <div>
                    
                  </div>
                  <div>
                    Dt. Venc.: {{ formatDate(item.empreendcobr_parc_dt) }} | Valor: {{ item.empreendcobr_parc_valor ? formatPrice(item.empreendcobr_parc_valor) : '' }}
                  </div>
                  <div>
                    Dt. Pagto: {{ item.empreendcobr_pagto_dt ? formatDate(item.empreendcobr_pagto_dt) : '' }} | Valor Pagto: <v-tooltip top v-if=" item.empreendcobr_parc_acres_valor ||
                                          item.empreendcobr_parc_desc_valor ||
                                          item.empreendcobr_pagto_valor_multa ||
                                          item.empreendcobr_pagto_valor_juros
                                          ">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon small v-bind="attrs" v-on="on" color="red">mdi-cash</v-icon>
                      </template>
                      <span v-if="item.empreendcobr_parc_acres_valor"   align="right">{{ 'Acréscimo: ' + formatPrice(item.empreendcobr_parc_acres_valor)}}<br></span>
                      <span v-if="item.empreendcobr_parc_desc_valor"    align="right">{{ 'Desconto: ' + formatPrice(item.empreendcobr_parc_desc_valor)  }}<br></span>
                      <span v-if="item.empreendcobr_pagto_valor_multa"  align="right">{{ 'Multa: ' + formatPrice(item.empreendcobr_pagto_valor_multa)   }}<br></span>
                      <span v-if="item.empreendcobr_pagto_valor_juros"  align="right">{{ 'Juros: ' + formatPrice(item.empreendcobr_pagto_valor_juros)   }}<br></span>
                    </v-tooltip>
                    {{ item.empreendcobr_pagto_valor ? formatPrice(item.empreendcobr_pagto_valor) : '' }}
                  </div>
                
 
                </td>
              </tr>

              <!-- SubGrade -->
              <!-- <tr  v-if="expanded===index && item.empreendcobr_impressao_via > 0" class="data-table-impressos-fundo">
                <td colspan="13">
                <v-data-table
                  class="data-table ml-10 data-table-impressos"
                  :items="boletos"
                  hide-default-footer
                  single-expand
                >
                  <template v-slot:header >
                    <thead >
                      <tr>
                        <th v-for="h_i in headers_impressos" :key="h_i.value" :class="h_i.class" :style="h_i.style">
                          <span class="d-flex flex-nowrap body-2 font-weight-bold">{{h_i.text}}</span>
                        </th>
                      </tr>
                    </thead>
                  </template>

                  <template #item="{ item }">
                    <tr>
                      <td style="max-width: 40px">
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="grey"
                              dark
                              icon
                              v-bind="attrs"
                              v-on="on"
                              class="ml-n4"
                            >
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>

                          <v-list class="">
                            <v-list-item @click="() => {}"
                              >
                              <v-list-item-title
                                @click="baixarBoleto(item)"
                                ><v-icon color="green" class="mr-2 icon-menu"
                                  >mdi-cash-multiple</v-icon
                                >
                                Pagar Boleto
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item  @click="() => {}"
                              >
                              <v-list-item-title
                                @click="reimprimirBoleto(item)"
                                ><v-icon color="blue" class="mr-2 icon-menu"
                                  >mdi-printer</v-icon
                                >
                                Reimprimir Boleto
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item  @click="() => {}"
                              >
                              <v-list-item-title
                                @click="excluirBoleto(item)"
                                ><v-icon color="red" class="mr-2 icon-menu"
                                  >mdi-delete</v-icon
                                >
                                Excluir Boleto
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </td>
                      <td>{{item.empreendcobr_impressao_via}}</td>
                      <td>{{item.empreendcobr_impressao_tipo}}</td>
                      <td>{{formatDateTime(item.empreendcobr_impressao_dt)}}</td>
                      <td>{{item.cod_empreendcobranca_impressao}}</td>
                      <td>{{item.empreendcobr_impr_valor_final}}</td>
                      <td>{{formatDate(item.empreendcobr_impr_vencto_dt)}}</td>
                      <td>{{`${item.empreendcobr_nossonumero} - ${item.empreendcobr_nossonumero_dig}`}}</td>
                    </tr>
                  </template>
                </v-data-table>
                <br>
                </td>
              </tr> -->

            </template>

          </v-data-table>

            <!-- LEGENDA COBRANÇAS -------------------------------------------------------->
            <div class="mt-2 mb-1 ml-1">
              <table class="d-flex justify-start">
                <tbody class="">
                  <tr class="text sticky-table-footer-desktop d-flex mb-2">
                    <td align="left" style="display: flex">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on" class="mr-1 status_legend status_legend_a_vencer">
                            <v-icon color="#FFFFFF" dense class="ml-n1 mt-n1">mdi-check</v-icon>
                            <!-- <span class="body-1 mr-2" style="color:#E0E0E0">({{total_aberto_parc}} / R$ {{ formatNumber(total_aberto_valor)}})</span> -->
                            <span class="mr-1 font-weight-thin body-2" style="color:#fff">À vencer:</span>
                            <span class="mr-2 font-weight-medium body-1 " style="color:#fff"> {{total_aberto_parc}} R$ {{ formatNumber(total_aberto_valor)}}</span>
                          </span>
                        </template>
                        <span>Cobranças abertas em dia</span>
                      </v-tooltip>
                    </td>

                    <td align="left" style=" background-color:#FFFFFF; display: flex">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on" class="mr-1 status_legend status_legend_vencidas">
                            <v-icon color="#FFFFFF" dense class="ml-n1 mt-n1">mdi-alert-octagon-outline</v-icon>
                            <span class="mt-n2 mr-1 font-weight-thin body-2"  style="color:#fff">Vencidas:</span>
                            <span class="mt-n2 mr-2 font-weight-medium body-1" style="color:#fff"> {{total_vencidas_parc}} R$ {{ formatNumber(total_vencidas_valor)}}</span>
                          </span>
                        </template>
                        <span>Cobranças abertas com pagamento em atraso</span>
                      </v-tooltip>
                    </td>

                    <td align="left" style=" background-color:#FFFFFF; display: flex">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on" class="mr-1 status_legend status_legend_pagos">
                            <v-icon color="#FFFFFF" dense class="ml-n1 mt-n1">mdi-check</v-icon>
                            <span class="mt-n2 mr-1 font-weight-thin body-2"  style="color:#fff">Pagos:</span>
                            <span class="mr-2 font-weight-medium body-1" style="color:#fff">{{total_pago_parc}} <span style="white-space: nowrap">R$ {{ formatNumber(total_pago_valor)}}</span></span>
                          </span>
                        </template>
                        <span>Cobranças pagas já baixadas no sistema</span>
                      </v-tooltip> 
                    </td>
                    <td v-if="total_parc_selecionado > 0" align="left" style=" background-color:#FFFFFF; display: flex">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on" class="mr-1 status_legend status_legend_selecionados">
                            <v-icon color="#FFFFFF" dense class="ml-n1 mt-n1">mdi-check</v-icon>
                            <span class="mt-n2 mr-1 font-weight-thin body-2"  style="color:#fff">Seleção (Qtde / Aberto / Pago):</span>
                            <span class="mr-2 font-weight-medium body-1" style="color:#fff">{{total_parc_selecionado}} / R$ {{ formatNumber(total_parc_valor_selecionado)}} / R$ {{ formatNumber(total_pago_valor_selecionado)}}</span>
                          </span>
                        </template>
                        <span>Cobranças selecionadas</span>
                      </v-tooltip> 
                    </td>
                  </tr>
                </tbody>
              </table>
              </div>
            <!-- FIM RODAPE COBRANÇAS -------------------------------------------------------->

          </v-container>
        </v-col>
        <!-- Fim Grade ----------------------------------->
      </v-row>

    <v-snackbar
      v-model="dialog_excluir_alert"
      :timeout="4000"
      :color="dialog_excluir_message"
      :centered="false"
      :bottom="false"
      :top="true"
      max-width="400px"
      height="400px"
      :multi-line="true"
      :vertical="true"
      elevation="5"
      :light="false"
      :outlined="false"
      rounded="5"
      :shaped="false"
    >
      {{dialog_excluir_msg}}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="dialog_excluir_alert = false"
          class="text-none"
          dark
        >
          <br>
          Fechar
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="dialog_estornar_alert"
      :timeout="4000"
      :color="dialog_estornar_message"
      :centered="false"
      :bottom="false"
      :top="true"
      max-width="400px"
      height="400px"
      :multi-line="true"
      :vertical="true"
      elevation="5"
      :light="false"
      :outlined="false"
      rounded="5"
      :shaped="false"
    >
      {{dialog_estornar_msg}}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="dialog_estornar_alert = false"
          class="text-none"
          dark>
          <br>
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
    
  </div>
 
</template>

<script>
import { API, API_CNAB } from "../../../services/API"
import store_usuario from "../../../store/store_usuario";
import store_ContasReceber from "../../Financeiro/ContasReceber/store_ContasReceber";
import store_site from "../../../../src/store/store_site"
import { ContasReceberGet } from "../../../services/erp";
import { COR_PRINCIPAL, COR_SECUNDARIA, COR_SECUNDARIA_2, COR_SUBTITULO } from "../../../services/constantes";
import { maskCpfCnpj, formatDate, formatDateTime, formatNumber, formatPrice } from "../../../services/funcoes";
// import DatePicker from "../../ComponentesGlobais/Date-Picker/date-picker.vue";
//import ContasReceberModal from "../../Financeiro/ContasReceber/ContasReceberModal.vue";
//import ContasReceberNovoModal from "../../Financeiro/ContasReceber/ContasReceberNovoModal.vue";
import moment, { now } from 'moment';
import { saveAs } from 'file-saver';
import { AbreRelatorioExterno, Relatorio } from "../../../services/global";
import exportFromJSON from "export-from-json";
// import MenuRelatorios from "../../ComponentesGlobais/MenuRelatorios/MenuRelatorios.vue"
//import GedDocumentos from "../../Ged/GedDocumentos.vue";
//---------------------- fim teste download excel --------

export default {
  name: "ExtratoFinanceiro",

  props: ['acao', 'dialog_ContasReceber', 'dialog_lancamentos', 'itens_array_pai', 'parc_valor'],

  components: {
    // DatePicker,
    // ContasReceberModal,
    // ContasReceberNovoModal,
   // GedDocumentos,
    // MenuRelatorios
  },

  data() {
    return {
      user : { username : null }, 
 
      initDate                             : null,
      endDate                              : null,
      store_usuario                        : store_usuario,
      store_ContasReceber                  : store_ContasReceber,
      store_site                           : store_site,
 
      ContasReceberGet                     : ContasReceberGet,
      dadosExtrato                         : [],
 
      empreendimento                      : {},
      boletos                              : [],
 
      maskCpfCnpj                          : maskCpfCnpj,
      formatDate                           : formatDate,
      formatDateTime                       : formatDateTime,
      formatNumber                         : formatNumber,
      formatPrice                          : formatPrice,
 
      loading                              : false,
      search                               : null,
      search_input_focus                   : false,
 
      loading_excluir                      : false,
      loading_relatorio                    : false,
      //filtro                             : null,

      expanded                             : null,
      toolsIndex                           : null,
 
      currentPage                          : 1,
      count_dados                          : null,
      total_aberto_parc                    : 0,
      total_aberto_valor                   : 0,
      total_vencidas_parc                  : 0,
      total_vencidas_valor                 : 0,
      total_pago_parc                      : 0,
      total_pago_valor                     : 0,
      total_parc_selecionado               : 0,
      total_parc_valor_selecionado         : 0,
      total_pago_valor_selecionado         : 0,

      isActiveVencidos                      : false,
      isActiveHoje                          : false,
      isActiveSemana                        : false,
      isActiveMes                           : false,
 
      situacao_atual                       : null,
 
      COR_PRINCIPAL                        : COR_PRINCIPAL,
      COR_SECUNDARIA                       : COR_SECUNDARIA,
      COR_SECUNDARIA_2                     : COR_SECUNDARIA_2, 
      COR_SUBTITULO                        : COR_SUBTITULO,
      accentColor                          : '#f00',
 
      dialog_excluir                       : false,
      dialog_excluir_alert                 : false,
      dialog_excluir_msg                   : false,
      dialog_excluir_message               : null,
 
      dialog_excluir_confirmacao_1         : null,
      dialog_excluir_confirmacao_2         : null,
      dialog_excluir_alert_confirmacao     : false,
      dialog_excluir_msg_confirmacao       : false,
      dialog_excluir_message_confirmacao   : null,
 
      dialog_estornar                      : false,
      dialog_estornar_alert                : false,
      dialog_estornar_msg                  : false,
      dialog_estornar_message              : null,
 
      dialog_estornar_confirmacao_1        : false,
      dialog_estornar_confirmacao_2        : false,
      dialog_estornar_alert_confirmacao    : false,
      dialog_estornar_msg_confirmacao      : false,
      dialog_estornar_message_confirmacao  : null,

      dialog_pagar                         : false,
      dialog_pagar_msg                     : false,
      dialog_pagar_message                 : null,

      dialogIndiceCadastro                 : false,
      dialog_novo_editar                   : false,
      dialog_novo                          : false,

      dialog_documentos                    : false,
      cod_empreendcobranca_aux             : null,
      dados_documentos                     : null,
    
      selected: [],
      lotes : [],
      /* Título container-rounded */
      headers: [
        { text: "#", value: "cod_empreendcobranca"},
        { text: "Situação", value: "empreendcobr_situacao" },
        { text: "Parcelas", value: "quant_parcelas" },
        { text: "Dt. Venc.", value: "empreendcobr_parc_dt" },
        { text: "Parc. Valor", value: "empreendcobr_parc_valor" },
        { text: "Dt. Pagto", value: "empreendcobr_pagto_dt" },
        { text: "Valor Pagto", value: "empreendcobr_pagto_valor", style: "width:auto!important" },
        { text: "Cliente", value: "cliente", style: "min-width:330px!important" },
        { text: "Empreendimento", value: "empreendimento" },
        { text: "Endereço", value: "endereco" },
      ],
      headers_impressos: [
        { text: "",               value: "", style: "max-width:40px!important" },
        { text: "Via",            value: "empreendcobr_impressao_via",},
        { text: "Tipo",           value: "empreendcobr_impressao_tipo" },
        { text: "Data Impressão", value: "empreendcobr_impressao_dt"},
        { text: "Cód. Impressão", value: "cod_empreendcobranca_impressao" },
        { text: "Valor",          value: "empreendcobr_impr_valor_final" },
        { text: "Vencto Boleto",  value: "empreendcobr_impr_vencto_dt" },
        { text: "Nosso Número",   value: "empreendcobr_nossonumero" },
      ],
			filters: {
        //a: [],
        //b: [],
        empreendcobr_situacao: [],
        //cod_empreendcobranca: [],
				empreendcobr_impressao_via: [],
				parcela: [],
        parc_entr_dt: [],
				parc_valor_final: [],
			},

      /* Tabela Consulta */
      cobrancas: [],

      /* Registro que será Incluido ou Editado */
      dados_editar : {},

      /* Opções */
      opcoes_situacao: [
                          { "id": 1, text: "Aberto", value: "Aberto" },
                          { "id": 2, text: "Pago", value: "Pago" },
                          { "id": 3, text: "Rescindido", value: "Rescindido" },
                        ],

      opcoes_tipo_parcela:
                        [
                          { "id": 1, text: "Entrada", value: "ENTRADA" },
                          { "id": 2, text: "Parcela", value: "PARCELA" },
                          { "id": 3, text: "Renegociação", value: "RENEGOCIACAO" },
                          { "id": 4, text: "Residuo", value: "RESIDUO" },
                          { "id": 5, text: "Acordo", value: "ACORDO" },
                          { "id": 6, text: "Despesa", value: "DESPESA" },
                          { "id": 7, text: "Manual", value: "MANUAL" },
                        ],
                        
      opcoes_parcela:
                        [
                          { "id": 1, text: "Mensal", value: "Mensal" },
                          { "id": 2, text: "Bimestral", value: "Bimestral" },
                          { "id": 3, text: "Trimestral", value: "Trimestral" },
                          { "id": 4, text: "Semestral", value: "Semestral" },
                          { "id": 5, text: "Anual", value: "Anual" },
                        ],
                        
      opcoes_tipo_impressao:
                        [
                          { "id": 1, text: "Não Impressos", value: "Não Impressos" },
                          { "id": 2, text: "Impressos (Boletos e Antecipações)", value: "Impressos (Boletos e Antecipações)" },
                          { "id": 3, text: "Impressos (Somente Boletos)", value: "Impressos (Somente Boletos)" },
                          { "id": 4, text: "Impressos (Somente Antecipações)", value: "Impressos (Somente Antecipações)" },
                        ],
                        
      opcoes_bloqueados:
                        [
                          { "id": 1, text: "Sim", value: "Sim" },
                          { "id": 2, text: "Não", value: "Não" },
                        ],
                        
      opcoes_reajustados:
                        [
                          { "id": 1, text: "Sim", value: "Sim" },
                          { "id": 2, text: "Não", value: "Não" },
                        ],



      /* Inputs e Menus Data */
      menu    : false,
      //venc_final2   : null,
      filtro_local: {},
      
      filtro_mesmo_valor       : false,
      filtro_mesma_data_pagto  : false,
      filtro_mesmo_valor_cnab  : true,

      data_picker : {
        'min-width':'150px !important',
        //'max-width':'180px !important',
        //'margin-right':'8px !important',
        //'margin-top':'-15px !important',
        //'color':'red'
      },

      tree_opcoes : null,
      menu_config : [
                    {
                      "id": "Vendas",
                      "name": "Vendas",
                      "icon": "mdi-folder",
                      "children": [
                        {
                          "id": "Vendas (Período)",
                          "name": "Vendas (Período)",
                          "icon": "mdi-view-headline"
                        }
                      ]
                    },
                    {
                      "id": "Gerencial",
                      "tipo": "relatorio",
                      "icon": "mdi-folder",
                      "path": "/painel_gestor",
                      "name": "Gerencial",
                      "children": [
                        {
                          "id": "Situação das Unidades",
                          "name": "Situação das Unidades",
                          "icon": "mdi-view-headline"
                        }
                      ]
                    }
                  ]
    };
  },

  mounted() {
    if (this.$route.query.ViscomSGI) {
      this.store_site.ViscomSGI = this.$route.query.ViscomSGI;
    }
    else
      this.store_site.ViscomSGI = null;

    // Busca privilegios de acesso ao empreendimento
    this.empreendimento_privilegio_acesso();
    if (this.acao == 'SELECIONAR') {
      this.filtroMesmoValorCNAB();
    }
    this.populaSelect()
  },
  
  computed:{
    isMobile(){
      return this.$vuetify.breakpoint.name === 'xs'
    },

    tableHeight() {
      if (this.isMobile)
        return window.innerHeight - 149 + 30 - 145;
      else
        if (this.acao == 'SELECIONAR')
          return window.innerHeight - 149 - 120;
        else
          return window.innerHeight - 149 - 92;
    },

  	filteredDesserts() {
      return this.cobrancas.filter((d) => {
        return Object.keys(this.filters).every((f) => {
          return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
        });
      });
		},	

  },

  methods: {
    async imprimirRelatorio(empreendimento){
      console.log("+++++++++++++++++++ ~ imprimirRelatorio ~ empreendimento:", empreendimento.cod_empreendvenda)

      if(!!empreendimento.cod_empreendvenda){
        await AbreRelatorioExterno("Extrato Financeiro",
                                      [{ Name: "cod_empreendvenda", Value: empreendimento.cod_empreendvenda},
                                       { Name: "cod_pessoa",        Value: this.store_usuario.user.cod_pessoa}]);

      }
      else {
        this.store_site.alert_cor       = "#FF0000";
        this.store_site.alert_timeout   = 10000;
        this.store_site.alert_msg       = 'Escolha os dados para impressão do extrato de Pagos';
        this.store_site.alert           = true;
      }

    },
    
    async buscaDados(){
    console.log("🚀 ~ buscaDados ~ e:", this.empreendimento.value)
    if(this.empreendimento.value){
      let lo_params =  { num_proposta : this.empreendimento.value}
      let la_JSON = await ContasReceberGet(lo_params);
      this.dadosExtrato =  la_JSON.rows

    }

    },
    async populaSelect(){
      var ls_param = {params: { cliente : this.store_usuario.user.cod_pessoa},
                 }
    const {data} = await API.get("venda", ls_param);
      if(data.result){
        this.lotes = data.result;
      }
    },
    changeVencimento(val){
      this.isActiveVencidos = false
      this.isActiveHoje = false
      this.isActiveMes = false
      this.isActiveSemana = false
    },
    
    vencendo(dias, vmodel) {
      this.changeVencimento();
      if (vmodel === 'isActiveVencidos') {
        this.isActiveVencidos = true
      }
      if (vmodel === 'isActiveHoje') {
        this.isActiveHoje = true
      }
      else if (vmodel === 'isActiveSemana') {
        this.isActiveSemana = true
      }
      else if (vmodel === 'isActiveMes') {
        this.isActiveMes = true
      }
      
      this.filtro_local.parc_dt_inicial = moment().format();
      if (vmodel === 'isActiveVencidos') {
        this.isActiveVencidos = true
        this.filtro_local.parc_dt_inicial = null;
      }
      this.filtro_local.situacao = "Aberto";
      let data = moment(); // cria um objeto moment com a data atual
      data = data.add(dias, 'days'); // adiciona dias ao objeto moment
      this.filtro_local.parc_dt_final = data.format(); // exibe a nova data formatada
    },

    fechar() {
      this.$emit('update:dialog_ContasReceber', null);
    },

    selecionar() {
      const itens = this.cobrancas.filter((d) => d.value == true).map((v) => {return v})
      this.$emit('itemSelecionado', itens);
      this.fechar();
    },
    
    filtroMesmoValor() {
      if (this.filtro_mesmo_valor) {
        this.filtro_local.parc_valor_inicial = Math.abs(this.dialog_lancamentos?.valor);
        this.filtro_local.parc_valor_final   = Math.abs(this.dialog_lancamentos?.valor);
      }
      else {
        this.filtro_local.parc_valor_inicial = null;
        this.filtro_local.parc_valor_final   = null;
      }
      this.busca(1);
    },

    filtroMesmoValorCNAB() {
      if (this.filtro_mesmo_valor_cnab) {
        this.filtro_local.parc_valor_cnab = Math.abs(this.dialog_lancamentos?.valor);
        this.filtro_local.parc = Math.abs(this.dialog_lancamentos?.valor);
        this.filtro_local.parc_valor_cnab   = Math.abs(this.dialog_lancamentos?.valor);
      }
      else {
        this.filtro_local.parc_valor_cnab   = null;
      }
      this.busca(1);
    },

    filtroMesmaDataPagto() {
      if (this.filtro_mesma_data_pagto) {
        this.filtro_local.parc_dt_pagto_inicial = this.dialog_lancamentos?.data;
        this.filtro_local.parc_dt_pagto_final   = this.dialog_lancamentos?.data;
      }
      else {
        this.filtro_local.parc_dt_pagto_inicial = null;
        this.filtro_local.parc_dt_pagto_final   = null;
      }
      this.busca(1);
    },

    expandRow(index, item) {
      //console.log('row expanded: ', item);
      if (item.empreendcobr_impressao_via > 0) {
        if (this.expanded != index) {
          this.boletos = [];
          this.expanded = index;
          this.impressos(item.cod_empreendcobranca)
        }
        else {
          this.boletos = [];
          this.expanded = null;
        }
      }
      else {
        this.boletos = [];
        this.expanded = null;
      }
    },

    async export_table(dados, tipo, nome_arquivo) {
      //dados.forEach( e => ({value: "sssssss", ...e}))
      //console.log("this.cobrancas",this.cobrancas)


    const lo_Obj = this.cobrancas.map((e) => ({   
                                                situacao        : e.empreendcobr_situacao,
                                                documento       : e.cod_empreendcobranca,
                                                via             : e.empreendcobr_impressao_via,
                                                parcela         : e.empreendcobr_descricao_texto,
                                                vencimento      : formatDate(e.empreendcobr_parc_dt),
                                                valor           : e.empreendcobr_parc_valor_final,
                                                dt_pagto        : formatDate(e.empreendcobr_pagto_dt),
                                                vl_pagto        : e.empreendcobr_pagto_valor,
                                                cliente         : e.empreend_venda.pessoa_venda.pessoa_nome,
                                                unidade         : e.empreend_venda.empreendvenda_imovel.quadra + ' | ' + 
                                                                  e.empreend_venda.empreendvenda_imovel.lote,
                                                contrato        : e.empreend_venda.empreendvenda_contrato,
                                                empreendimento  : e.empreend_venda.vendas.empreend_nome,
                                                proposta        : e.empreend_venda.ev,
                                                endereco        : e.empreend_venda.pessoa_venda.pessoa_enderecos[0].end_lograd + ", " + 
                                                                  e.empreend_venda.pessoa_venda.pessoa_enderecos[0].end_num + ", " + 
                                                                  e.empreend_venda.pessoa_venda.pessoa_enderecos[0].end_bairro,
                                                }));
      //console.log("lo_Obj",lo_Obj)
      dados = lo_Obj;
      const data = dados;
      //console.log('dadosExcel', data)
      const fileName = nome_arquivo;
      let exportType;
      if (tipo == 'xls')
        exportType = exportFromJSON.types.xls;
      if (tipo == 'csv')
        exportType = exportFromJSON.types.csv;
      if (tipo == 'pdf')
        exportType = exportFromJSON.types.txt;
      if (data)
        exportFromJSON({ data, fileName, exportType });
    },

    //------------ teste download excel -----------------------
    //     exportSheet() {  
    //   const spread = this._spread;  
    //   const fileName = "SalesData.xlsx";  
    //   const sheet = spread.getSheet(0);  
    //   const excelIO = new Excel.IO();  
    //   const json = JSON.stringify(spread.toJSON({  
    //       includeBindingSource: true,  
    //       columnHeadersAsFrozenRows: true,  
    //   }));  
    //   excelIO.save(json, function(_blob_){  
    //     saveAs(blob, fileName);  
    //   }, function (_e_) {   
    //     //console.log(e)   
    //   });  
    // },

    //async cnab_remessa_240_baixar(cod_empreendcobrancacnab){
    //async exportSheet(){
    //  //console.log('entrou aqui', this.dados_editar.data)
    // this.loading_baixando = true;
    // const resposta = await API.get(`cnab_remessa_baixar`, {params:{cod_empreendcobrancacnab}})
    // if (resposta) {
    //   ////console.log('RESPOSTA -- ', resposta);
    //   var a = document.createElement("a"); //Create <a>
    //   a.href = "data:file/txt;base64," + resposta.data.result.arquivo_conteudo; //Image Base64 Goes here
    //   a.download = resposta.data.result.arquivo_nome; //File name Here
    //   a.click(); //Downloaded file
    // }
    // this.loading_baixando = false;
    //},

    limpa_todos() {
      for(let prob in this.filtro_local){
        this.filtro_local[prob] = null
      }
      // this.store_ContasPagar.filtro = []
      this.isActiveVencidos = false
      this.isActiveHoje     = false
      this.isActiveMes      = false
      this.isActiveSemana   = false
    },

    async busca(page) {
      if (page)
        this.currentPage = page;
      this.loading  = true;
      this.menu     = false;
      this.boletos  = [];
      this.expanded = null;
      this.store_ContasReceber.filtro = {...this.filtro_local};

      var lo_params =  { num_proposta : this.empreendimento.cod_empreendvenda}
      let la_JSON;
      la_JSON = await ContasReceberGet(lo_params);
      console.log("🚀 ~ busca ~ la_JSON:", la_JSON.rows)

      this.count_dados = la_JSON.count;
      this.total_aberto_parc                    = 0;
      this.total_aberto_valor                   = 0;
      this.total_vencidas_parc                  = 0,
      this.total_vencidas_valor                 = 0;
      this.total_pago_parc                      = 0;
      this.total_pago_valor                     = 0;
      this.total_parc_selecionado               = 0;
      this.total_parc_valor_selecionado         = 0;
      this.total_pago_valor_selecionado         = 0;
      
      // if (la_JSON.rows && la_JSON.rows.length > 0)  {
      //   this.store_ContasReceber.paginas = la_JSON.paginas;
        this.cobrancas = la_JSON.rows.map(v => ({value: false, 
                                                docs: v.cobr_gedvinculo.reduce((acc, item) => {
                                                                                          const { tipo_documento } = item;
                                                                                          if (!acc[tipo_documento]) {
                                                                                            acc[tipo_documento] = 0; // Inicializa a contagem se a chave ainda não existe
                                                                                          }
                                                                                          acc[tipo_documento] += 1; // Incrementa a contagem
                                                                                          return acc;
                                                                                        }, {}),
                                                ...v,}));
        await this.rodape()
      // }
      // else
      //   this.cobrancas = [];

      this.loading = false;

      // Posiciona o Scrool no começo da tabela 
      // this.$vuetify.goTo('.data-table tbody tr',
      //                     { offset: 80,
      //                       container: '.v-data-table__wrapper'
      //                     }
      //                   )
    },

    async rodape(){
      // Somando valores do rodapé
      this.total_aberto_parc      = 0;
      this.total_aberto_valor     = 0;
      this.total_vencidas_parc    = 0,
      this.total_vencidas_valor   = 0;
      this.total_pago_parc        = 0;
      this.total_pago_valor       = 0;
      this.total_parc_selecionado = 0;
      this.total_parc_valor_selecionado = 0;
      this.total_pago_valor_selecionado = 0;
      this.situacao_atual         = null;
      this.cobrancas.forEach(elem => {
        this.situacao_atual       = elem.empreendcobr_situacao
        if(moment().format('YYYY-MM-DD') <= moment(elem.empreendcobr_parc_dt).format('YYYY-MM-DD') && 
            elem.empreendcobr_pagto_dt == null &&
            elem.empreendcobr_situacao === 'Aberto') {
          this.total_aberto_parc    += elem.empreendcobr_situacao === 'Aberto' ? 1 : 0;
          this.total_aberto_valor   += elem.empreendcobr_situacao === 'Aberto' ? elem.empreendcobr_parc_valor_final : 0;
        }
        else if(moment().format('YYYY-MM-DD') > moment(elem.empreendcobr_parc_dt).format('YYYY-MM-DD') && 
                  elem.empreendcobr_pagto_dt == null &&
                  elem.empreendcobr_situacao === 'Aberto') {
          this.total_vencidas_parc  += elem.empreendcobr_situacao === 'Aberto' ? 1 : 0;
          this.total_vencidas_valor += elem.empreendcobr_situacao === 'Aberto' ? elem.empreendcobr_parc_valor_final : 0;
        }
        this.total_pago_parc        += elem.empreendcobr_situacao === 'Pago'   ? 1 : 0;
        this.total_pago_valor       += elem.empreendcobr_situacao === 'Pago'   ? elem.empreendcobr_pagto_valor : 0;
        elem.empreendcobr_descricao_texto = elem.empreendcobr_descricao[0].toUpperCase() +
                                            elem.empreendcobr_descricao.substring(0,4).toLowerCase().slice(1) + ' (' +
                                            elem.empreendcobr_parcela_inicial + '/' +
                                            elem.empreendcobr_parcela_final + ')';
                                            //this.total_pago_parc        += elem.empreendcobr_situacao === 'Pago'   ? 1 : 0;
        
        // Totais para os Selecionados
        this.total_parc_selecionado       += (elem.value) ? 1 : 0;
        this.total_parc_valor_selecionado += (elem.value && elem.empreendcobr_situacao === 'Aberto') ? elem.empreendcobr_parc_valor_final : 0;
        this.total_pago_valor_selecionado += (elem.value && elem.empreendcobr_situacao === 'Pago') ? elem.empreendcobr_pagto_valor : 0;
      });
    },

    selecionaTodos(e) {
      this.$nextTick(() => {
        this.cobrancas.map((v) => {v.value = e});
        this.rodape();
      })
    },

    async empreendimento_privilegio_acesso() {
      const resp = await API.get(`empreendimento_privilegio_acesso`);
      if (resp) {
        var la_Resp = resp.data.result;
        this.empreendimentos = la_Resp;
      }
    },

    // Função para enviar dados para o filho ContasReceberModal.vue e realizar a edição
    async editar(item) {
      const resp = await ContasReceberGet({cod_empreendcobranca: item.cod_empreendcobranca});
      if (resp) {
        this.dados_editar = {...resp};
        //atribui uma ação ao JSON para usar no filho
        this.dados_editar.acao = 'EDITAR'
        // this.dados_editar.empreendcobr_obs = !this.dados_editar.empreendcobr_obs ? ' ' : this.dados_editar.empreendcobr_obs;
        // this.dados_editar.empreendcobr_parc_acres_obs = !this.dados_editar.empreendcobr_parc_acres_obs ? ' ' : this.dados_editar.empreendcobr_parc_acres_obs;
        // this.dados_editar.empreendcobr_parc_desc_obs = !this.dados_editarempreendcobr_parc_desc_obs ? ' ' : this.dados_editar.empreendcobr_parc_desc_obs;
        this.dados_editar.empreendcobr_parc_valor_final = this.dados_editar.empreendcobr_parc_valor_final;
        this.dados_editar.empreendcobr_pagto_valor_juros = this.dados_editar.empreendcobr_pagto_valor_juros
        this.dialog_novo_editar = true;
         // Atualizando a grade sem chamar API
        let lo_Retorno = await this.cobrancas.find((val) => val.cod_empreendcobranca == resp.cod_empreendcobranca);
        if (lo_Retorno) {
          lo_Retorno.empreendcobr_situacao               = item.empreendcobr_situacao;
          lo_Retorno.empreendcobr_pagto_valor            = item.empreendcobr_pagto_valor;
          lo_Retorno.empreendcobr_pagto_dt               = item.empreendcobr_pagto_dt;
          lo_Retorno.empreendcobr_pagto_valor_juros      = item.empreendcobr_pagto_valor_juros;
          lo_Retorno.empreendcobr_pagto_valor_multa      = item.empreendcobr_pagto_valor_multa;
          lo_Retorno.empreendcobr_parc_valor_final       = item.empreendcobr_parc_valor_final;
          lo_Retorno.empreendcobr_pagto_dt               = item.empreendcobr_pagto_dt;
        }            
      }
    },

    // Função que recebe alterações do filho ContasReceberModal.vue para atualizar a grade
    async atualiza_registro(p_JSON) {
      let lo_Retorno = await this.cobrancas.find((val) => val.cod_empreendcobranca == p_JSON.cod_empreendcobranca);
      if (lo_Retorno) {
        Object.keys(p_JSON).forEach(function (key) {
          lo_Retorno[key] = p_JSON[key];
        });  
      }
      // atualizar o rodapé
      await this.rodape()
    },

    // função para estornar
    async estornar(item){
      // console.log("🚀 ~ file: ContasReceber.vue:2384 ~ estornar ~ cod_empreendcobranca:", cod_empreendcobranca)
      // return
      //let lo_Retorno_ = await this.cobrancas.find((val) => val.cod_empreendcobranca == item);
      const lo_JSON = { cod_empreendcobranca                : item.cod_empreendcobranca,
                        empreendcobr_situacao               : "Aberto",
                        empreendcobr_pagto_valor            : null,
                        empreendcobr_pagto_dt               : null,
                        empreendcobr_pagto_valor_juros      : null,
                        empreendcobr_pagto_valor_multa      : null,
                        empreendcobr_parc_valor_final       : item.empreendcobr_parc_valor,
                        empreendcobr_pagto_dt               : null,
                        empreendcobr_parc_acres_valor       : null,
                        empreendcobr_parc_acres_obs         : null,
                        empreendcobr_parc_desc_valor        : null,
                        empreendcobr_parc_desc_obs          : null,
                      }
      this.dialog_estornar_confirmacao_2 = null;
      let ls_Res = null
      ls_Res = await this.store_ContasReceber.ContasReceberEstornar(lo_JSON);
      if (ls_Res.status == 200) {
            // Mensagem de estorno confirmado
            this.store_site.alert_cor       = "#00A000";
            this.store_site.alert_timeout   = 1500;
            this.store_site.alert_msg       = ls_Res.data.result.result.trim();
            this.store_site.alert           = true;
            let lo_Retorno = await this.cobrancas.find((val) => val.cod_empreendcobranca == item.cod_empreendcobranca);
            if (lo_Retorno) {
              // atualizar o rodapé
              await this.rodape()
              lo_Retorno.empreendcobr_situacao               = lo_JSON.empreendcobr_situacao;
              lo_Retorno.empreendcobr_pagto_valor            = lo_JSON.empreendcobr_pagto_valor;
              lo_Retorno.empreendcobr_pagto_dt               = lo_JSON.empreendcobr_pagto_dt;
              lo_Retorno.empreendcobr_pagto_valor_juros      = lo_JSON.empreendcobr_pagto_valor_juros;
              lo_Retorno.empreendcobr_pagto_valor_multa      = lo_JSON.empreendcobr_pagto_valor_multa;
              lo_Retorno.empreendcobr_parc_valor_final       = lo_JSON.empreendcobr_parc_valor;
              lo_Retorno.empreendcobr_pagto_dt               = lo_JSON.empreendcobr_pagto_dt;
              lo_Retorno.empreendcobr_parc_acres_valor       = lo_JSON.empreendcobr_parc_acres_valor;
              lo_Retorno.empreendcobr_parc_desc_valor        = lo_JSON.empreendcobr_parc_desc_valor;

              
            }             
      } else {
            // Mensagem de estorno não realizado
            this.store_site.alert_cor       = "#FF0000";
            this.store_site.alert_timeout   = 10000;
            this.store_site.alert_msg       = ls_Res.data.errors.trim();
            this.store_site.alert           = true;
          }
    },

    async excluir(item){
      //console.log('EXCLUINDO', item)
      let ls_Res = null
      ls_Res = await this.store_ContasReceber.ContasReceberExcluir(item);                         
      this.dialog_excluir_confirmacao_2 = null;
      if (ls_Res.status == 200) {
        // Mensagem de exclusão confirmada
        this.store_site.alert_cor       = "#00A000";
        this.store_site.alert_timeout   = 1500;
        this.store_site.alert_msg       = ls_Res.data.result.result.trim();
        this.store_site.alert           = true;
        // Atualizando a grade sem chamar API
        let i = this.cobrancas.findIndex(data => data.cod_empreendcobranca == item);
        if (i !== -1) {
          this.cobrancas.splice(i, 1);
        }
        //this.cobrancas.splice((val) => val.cod_empreendcobranca == item, 1);
        await this.rodape()
      } else {
        // Mensagem de exclusão não realizada
        this.store_site.alert_cor       = "#FF0000";
        this.store_site.alert_timeout   = 10000;
        this.store_site.alert_msg       = ls_Res.data.errors.trim();
        this.store_site.alert           = true;
      }

    },

    async novo(){
      this.dados_editar                                     = [];
      this.dados_editar.empreend_venda                      = {};
      this.dados_editar.empreend_venda.vendas               = {};
      this.dados_editar.empreend_venda.pessoa_venda         = {};
      this.dados_editar.empreend_venda.empreendvenda_imovel = {};
      this.dialog_novo                                      = true;
      //console.log('Node',this.dados_editar)
    },

    async impressos(item){
      ////console.log("entrou impressos", item)
      const ls_Res = await this.store_ContasReceber.ContasReceberImpressos(item); 
      this.boletos = ls_Res.data.result
      ////console.log('boletos',this.boletos)
      ////console.log("ls_Res",ls_Res.data.result)
    },

    // Função para enviar dados para o filho ContasReceberModal.vue pagamento/baixa 
    async pagar(item){
      this.dados_editar = [];
      const resp = await ContasReceberGet({cod_empreendcobranca: item.cod_empreendcobranca});
      // console.log("novo", resp)

      if (resp) {
        this.dados_editar = {...resp};
        // console.log("dados2", this.dados_editar, "item+++++++", item)
        //atribui uma ação ao JSON para usar no filho
        this.dados_editar.empreendcobr_situacao == 'Pago' ? this.dados_editar.acao2 = 'VISUALIZAR': this.dados_editar.acao2 = 'PAGAR';
        this.dados_editar.acao = 'PAGAR'
        //this.dados_editar.empreendcobr_pagto_dt = moment().locale('pt-br').format('L')
        this.dados_editar.empreendcobr_situacao != 'Pago' ? this.dados_editar.empreendcobr_pagto_dt = moment().locale('pt-br').format('L') : this.dados_editar.empreendcobr_pagto_dt = formatDate(item.empreendcobr_pagto_dt);
        // this.dados_editar.empreendcobr_obs = !this.dados_editar.empreendcobr_obs ? ' ' : this.dados_editar.empreendcobr_obs;
        // this.dados_editar.empreendcobr_parc_acres_obs = !this.dados_editar.empreendcobr_parc_acres_obs ? ' ' : this.dados_editar.empreendcobr_parc_acres_obs;
        // this.dados_editar.empreendcobr_parc_desc_obs = !this.dados_editarempreendcobr_parc_desc_obs ? ' ' : this.dados_editar.empreendcobr_parc_desc_obs;
        this.dialog_novo_editar = true;       
      }
    },

    // Função para enviar dados para o filho ContasReceberModal.vue e realizar vizualização
    async visualizar(item){
      this.dados_editar = [];
      const resp = await ContasReceberGet({cod_empreendcobranca: item.cod_empreendcobranca});
      if (resp) {
        this.dados_editar = {...resp};
        //atribui uma ação ao JSON para usar no filho
        this.dados_editar.acao = 'VISUALIZAR';
        //this.dados_editar.empreendcobr_pagto_dt = moment().locale('pt-br').format('L');
        this.dados_editar.empreendcobr_obs = !this.dados_editar.empreendcobr_obs ? ' ' : this.dados_editar.empreendcobr_obs;
        this.dados_editar.empreendcobr_parc_acres_obs = !this.dados_editar.empreendcobr_parc_acres_obs ? ' ' : this.dados_editar.empreendcobr_parc_acres_obs;
        this.dados_editar.empreendcobr_parc_desc_obs = !this.dados_editarempreendcobr_parc_desc_obs ? ' ' : this.dados_editar.empreendcobr_parc_desc_obs;
        this.dialog_novo_editar = true; 
        //console.log(this.dialog.dados_editar)      
      }
    },

    documentos(item) {
      this.dados_documentos = {...item}

      this.paramsTipoDocumento = {tipo_cadastro: 'cobranca', tipo_cadastro_descricao: "Documentos Pagar/Receber"}
      this.paramBusca = {cod_empreendcobranca : item.cod_empreendcobranca}
      this.statusDocumento = 'Aprovado'
      this.dados_documentos.tipo = 'CONTAS A RECEBER';
      this.cod_empreendcobranca_aux = item.cod_empreendcobranca
      this.dialog_documentos = true
    },

    async cnab() {
      const lo_JSON = { "type"  : "pdf",
                        "data"  : [ { "valor":5.0,
                                      "cedente":"Kivanio Barbosa",
                                      "documento_cedente":"12345678912",
                                      "sacado":"Claudio Pozzebom",
                                      "sacado_documento":"12345678900",
                                      "agencia":"0810",
                                      "conta_corrente":"53678",
                                      "convenio":12387,
                                      "nosso_numero":"12345678",
                                      "bank":"itau"
                                    },
                                    { "valor": 10.00,
                                      "cedente": "PREFEITURA MUNICIPAL DE VILHENA",
                                      "documento_cedente": "04092706000181",
                                      "sacado": "João Paulo Barbosa",
                                      "sacado_documento": "77777777777",
                                      "agencia": "1825",
                                      "conta_corrente": "0000528",
                                      "convenio": "245274",
                                      "nosso_numero": "000000000000001",
                                      "bank":"caixa"
                                    }
                                  ]
                      }

      // const resp = await API_CNAB.post(`/boleto/multi`, JSON.stringify(lo_JSON));

      // este abaixo deu CERTO
      // const resp = await API_CNAB.get('boleto/nosso_numero?bank=itau&data=%7B"valor":0.0,"cedente":"Kivanio%20Barbosa","documento_cedente":"12345678912","sacado":"Claudio%20Pozzebom","sacado_documento":"12345678900","agencia":"0810","conta_corrente":"53678","convenio":12387,"numero_documento":"12345678"%7D');
    
      // axios.post('http://localhost:9292/api', lo_JSON)
      //   .then(response => //console.log(response))
      //   .catch(error => //console.log(error)) 
    },

    // Acoes de Boletos
    async baixarBoleto(item) {
      let ls_Res = null
      //console.log('chamou function Front')
      ls_Res = await this.store_ContasReceber.ContasReceberImpressosBaixar(item); 
      //console.log('chamou function Front', ls_Res)                        
      //this.dialog_excluir_confirmacao_2 = null;
      if (ls_Res.status == 200) {
            // Mensagem de exclusão confirmada
            this.store_site.alert_cor       = "#00A000";
            this.store_site.alert_timeout   = 1500;
            this.store_site.alert_msg       = ls_Res.data.result.result.trim();
            this.store_site.alert           = true;
            // Atualizando a grade sem chamar API
            await this.cobrancas.splice((val) => val.cod_empreendcobranca == item, 1);

      } else {
            // Mensagem de exclusão não realizada
            this.store_site.alert_cor       = "#FF0000";
            this.store_site.alert_timeout   = 10000;
            this.store_site.alert_msg       = ls_Res.data.errors.trim();
            this.store_site.alert           = true;
          }
    },

    async reimprimirBoleto(item) {
      // const lo_Item = {
      //               "banco" : "Sicoob",
      //               "boletos"	: [
      //                     {
      //                       "pagador": {
      //                         "nome": "José Bonifácio de Andradaaaaaa",
      //                         "RegistroNacional": "12345678",
      //                         "endereco": {
      //                           "logradouro": "Rua Pedro Lessa, 15",
      //                           "bairro": "Centro",
      //                           "cidade": "Rio de Janeiro",
      //                           "estadoUF": "RJ",
      //                           "cep": "20030-030"
      //                         }
      //                       },
      //                       "instrucoes": ["Após o vencimento Mora dia R$ 1,59", "Após o vencimento, multa de 2%"],
      //                       "beneficiario": {
      //                         "nome": "Empresa Fictícia LTDA",
      //                         "cnpj": "03411967000154",
      //                         "dadosBancarios": {
      //                           "carteira": "1",
      //                           "agencia": "3214",
      //                           "agenciaDigito": "0",
      //                           "conta": "4995163",
      //                           "contaDigito": "0",
      //                           "nossoNumero": "0000001",
      //                         "nossoNumeroDigito": "0"
      //                         },
      //                         "endereco": {
      //                           "logradouro": "Rua Pedro Lessa, 15",
      //                           "bairro": "Centro",
      //                           "cidade": "Rio de Janeiro",
      //                           "estadoUF": "RJ",
      //                           "cep": "20030-030"
      //                         }
      //                       },
      //                       "boleto": {
      //                         "numeroDocumento": "1001",
      //                         "especieDocumento": "DM",
      //                         "valor": 110.00,
      //                         "datas": {
      //                           "vencimento": "02-04-2020",
      //                           "processamento": "02-04-2019",
      //                           "documentos": "02-04-2019"
      //                         }
      //                       }
      //                     }
      //               ]
      //             }      
      ////console.log("item", item)
      
      let la_Items = [];

      //////////////////////////////////////////////////////////////////////////////////////////
      // Relatorio Stimulsoft (BACKEND) -----------------------
      // for (var i = 0; i < 1; i++) {
      //   la_Items.push(parseInt(item.cod_empreendcobranca_impressao) + i);
      // }
      // const lo_Item =  [{Name: "COD_IMPRESSAO", Value : la_Items}]
      // let data = {
      //   nome        : "Financeiro.Boleto.Sicoob",
      //   tipo        : "pdf",
      //   parametros  : lo_Item,
      //   //mail       : { to      : ['fernando@viscom.com.br'],
      //   //               subject : 'Boleto Automático Viscom (teste)'},
      // };
      // this.loading_relatorio = true;
      // let ls_Resp = await Relatorio(data);
      // this.loading_relatorio = false;

      //////////////////////////////////////////////////////////////////////////////////////////
      // Relatorio Stimulsoft (FRONTEND) -----------------------
      for (var i = 0; i < 1; i++) {
        la_Items.push(parseInt(item.cod_empreendcobranca_impressao) + i);
        const resp = await API.get("/erp/contasreceber/contasreceber_reimprimirboletos",
                                  { params: {
                                      cod_empreendcobranca_impressao: parseInt(item.cod_empreendcobranca_impressao) + i
                                    }
                                  }
                                );
      }
      this.loading_relatorio = true;
      await AbreRelatorioExterno("Financeiro.Boleto." + item.banco_cod_compensacao,
                                  { Name: "COD_IMPRESSAO", Value: la_Items});
      this.loading_relatorio = false;

      //////////////////////////////////////////////////////////////////////////////////////////
      // Relatorio pdfME --------------------------
      // for (var i = 0; i < 200; i++) {
      //   la_Items.push(parseInt(item.cod_empreendcobranca_impressao) + i);
      // // la_Items.push( {
      // //                   "name": "Parra Imobiliária Ltda",
      // //                   "age": "4 years",
      // //                   "sex": "Male",
      // //                   "weight": "33 pounds",
      // //                   "breed": "Mutt",
      // //                   "vencimento1": formatDate(item.empreendcobr_impr_vencto_dt),
      // //                   "vencimento2": formatDate(item.empreendcobr_impr_vencto_dt),
      // //                   "valor1": formatNumber(item.empreendcobr_impr_valor),
      // //                   "valor2": formatNumber(item.empreendcobr_impr_valor),
      // //                   "cod_bar": item.empreendcobr_codigobarra,
      // //                   "linha_digitavel1": item.empreendcobr_linhadigitavel,
      // //                   "linha_digitavel2": item.empreendcobr_linhadigitavel,
      // //                   "nosso_numero1": item.empreendcobr_nossonumero,
      // //                   "nosso_numero2": item.empreendcobr_nossonumero,
      // //                 }
      // //               );
      // }
      // const ls_Items = la_Items.join(',')
      // //console.log('ls_Items', ls_Items);
      // const resp = await API.get("/erp/contasreceber/contasreceber_reimprimirboletos",
      //                             { params: {
      //                                 cod_empreendcobranca_impressao: ls_Items
      //                               }
      //                             }
      //                           );
      // if (resp.data && resp.data.result) {
      //   let data = {
      //     tipo: "pdfME",
      //     inputs: resp.data.result,
      //     destino: "EMAIL NAO",
      //   };
      //   this.loading_relatorio = true;
      //   await Relatorio(data);
      //   this.loading_relatorio = false;
      //   //console.log("resp.data.result", resp.data.result);
      // }

      //////////////////////////////////////////////////////////////////////////////////////////
    },

    async excluirBoleto(item) {
      let ls_Res = null
      //console.log('chamou function Front')
      ls_Res = await this.store_ContasReceber.ContasReceberImpressosExcluir(item); 
      //console.log('chamou function Front', ls_Res)                        
      //this.dialog_excluir_confirmacao_2 = null;
      if (ls_Res.status == 200) {
            // Mensagem de exclusão confirmada
            this.store_site.alert_cor       = "#00A000";
            this.store_site.alert_timeout   = 1500;
            this.store_site.alert_msg       = ls_Res.data.result.result.trim();
            this.store_site.alert           = true;
            // Atualizando a grade sem chamar API
            await this.cobrancas.splice((val) => val.cod_empreendcobranca == item, 1);

      } else {
            // Mensagem de exclusão não realizada
            this.store_site.alert_cor       = "#FF0000";
            this.store_site.alert_timeout   = 10000;
            this.store_site.alert_msg       = ls_Res.data.errors.trim();
            this.store_site.alert           = true;
          }
    },

    // Retorna os codigos das cobrancas selecionadas
    selecionados() {
      //console.log('selecionados')
			return this.cobrancas.filter((d) => d.value == true).map((v) => {return v.cod_empreendcobranca})
		},

    onBlur() {
      this.search_input_focus = false;
    },

    onFocus() {
      this.search_input_focus = true;
    },

    abriuConsulta(val) {
      //console.log( 'abriuConsulta', this.dados_editar)
      if (val) {
        this.$nextTick(() => {
          this.filtro_local = {...this.store_ContasReceber.filtro};
        });
      }
    },

    handlePageChange(value) {
      this.currentPage = value;

      // Posiciona o Scrool no começo da tabela 
      this.$vuetify.goTo('.data-table tbody tr',
                          { offset: 60,
                            container: '.v-data-table__wrapper'
                          }
                        )
      
    },

  }
}  

</script>

<style scoped>

  .title-page {
    font-family: "Montserrat", sans-serif;
    letter-spacing: 0px;
  }

  .text-white {
    color: #FFFFFF;
    letter-spacing: 0px;
  }

  .date-picker {
    max-width: 200px!important;
  }

  .table tr {
    width: 100%;
  }

  .table-row {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    background: #fff;
  }

  /* .table-row:hover {
    background: #f6f6f6 !important;
  } */

  .v-btn {
    display: inline-block;
  }

  .v-list--dense .v-list-item e{
    font-size: 0.5rem !important;
  }

  .v-list-item__content{
    overflow: visible;
  }
  /* .v-select{
    font-size: 0.5rem !important;
  } */

  /* .v-select__selections{
    height: 90px;
  } */
  @media (max-width: 900px) {
  #btn_opcoes{
    margin-top: 10px!important;
    margin-bottom: 20px!important;
  }
} 

  /*-----------------------------------*/
  /*------ RESPONSIVE GENERAL ------*/
  /*-----------------------------------*/
  .v-toolbar,
  .v-sheet {
    display: flex !important;
    flex-direction: column !important;
    padding: 0px !important;
  }

  .v-toolbar__content {
    padding-left: 0px !important;
  }

  .v-toolbar__title {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .icon-action {
    font-size: 1.3rem !important;
  }

  .container-principal {
    max-width: 100%;
    margin: auto !important;
  }

  .container-fundo {
    height: calc(100vh -30px) !important;
  }

  .container-rounded {
    background: #fff !important;
    border-radius: 12px  12px 0 0!important;
  }

  .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
    font-size: 1.5rem !important;
    height: 22px !important;
    background-color: rgb(245, 245, 245) !important;
  }

  .data-table-impressos-fundo {
    background-color: rgb(235, 235, 239) !important;
  }

  .data-table-impressos > .v-data-table__wrapper > table > thead > tr > th  {
    background-color: rgb(245, 245, 245) !important;
  }

  .data-table-impressos {
    background-color: rgb(255, 255, 255) !important;
  }

  .data-table-principal {
    background-color: rgb(255, 255, 255) !important;
  }

  .data-table-principal > .v-data-table__wrapper > table > thead > tr > th  {
    background-color: rgb(245, 245, 245) !important;
  }

  .data-table-principal > .v-data-table__wrapper > table > tbody > tr > th  {
    background-color: rgb(2, 245, 245) !important;
    height: 20px !important;
  }

  #virtual-scroll-table-tablet {
    display: none;
  }

  /* .table {
    display: block;
    table-layout: fixed;
    overflow: hidden;
    width: 100% !important;
    min-height: 300px !important;
  }

  .table-td {
    display: block;
    table-layout: fixed;
    overflow: hidden;
    width: 100% !important;
    min-height: 330px !important;
    height: 30px !important;
  } */

  .card-container {
    padding: 16px!important;
  }

  .false-filter {
    background-color:#6C87A2;
    height: 38px!important;
    min-width: 400px!important;
    /* max-width: 900px!important; */
    border-radius: 5px;
  }

  .false-text {
    width: calc(100%);
    text-align: left;
    font-size: 17px;
    font-weight: 500;
    color: #C9CBCA;
    cursor: pointer;
    overflow-x: hidden;
  }

  .false-icon {
    cursor: pointer;
  }

  .v-select__slot {
    display: none !important;
  }

  .checkbox {
    margin-bottom: 3px;
    margin-top: 1px;
  }

  /* ::v-deep .v-data-footer {
    align-content: center;
    color: var(--COR_PRINCIPAL) !important;  
    font-size:14px !important;
    height: 35px;
    padding-top: 10px !important;
  }

  ::v-deep .v-data-footer__select {
    display: none;
  }
 */

 .status_aberto_vencer {
    font-size: 12px !important;
    /* background: #6C87A2; */
    background: rgb(242, 192, 98);
    color: #fff;
    padding: 2px 12px;
    border-radius: 25px;
    height: 20px;
    white-space: nowrap;
  }
  .status_aberto_vencida {
    font-size: 12px !important;
    /* background: #6C87A2; */
    background: #FF5252;
    color: #fff;
    padding: 2px 12px;
    border-radius: 25px;
    height: 20px;
    white-space: nowrap;
  }  

  .status_pago {
    font-size: 12px !important;
    background: #469c47;
    color: #fff;
    padding: 2px 12px;
    border-radius: 25px;
    height: 20px;
    white-space: nowrap;
  }

  .status_inativo {
    font-size: 12px !important;
    background: #525252;
    color: #fff;
    padding: 2px 12px;
    border-radius: 25px;
    height: 20px;
    white-space: nowrap;
  }

  .status_rescindido {
    font-size: 12px !important;
    background: #A2A2A2;
    color: #fff;
    padding: 2px 12px;
    border-radius: 25px;
    height: 20px;
    white-space: nowrap;
  }


  .width-nome {
    display: block;
    min-width: 250px;
    overflow: hidden;
    /* white-space: nowrap; */
    text-overflow: ellipsis;
    z-index: 3;
  }

  .width-parcela {
    min-width: 120px;
    white-space: nowrap;
  }

  .width-nome-customer {
    white-space: nowrap;
  }

  .width-empreendimento {
    display: block;
    min-width: 200px;
    overflow: hidden;
    /* white-space: nowrap; */
    text-overflow: ellipsis;
    z-index: 3;
  }

  .linhas {
    height:55px;
    background-color:transparent;
  }

  .linhas2 {
    height:55px;
    background-color:transparent;
  }

  /* PEGA OS ESTILOS DO BLOCO ACIMA E MUDA SÓ O PADDING */
  .status_legend {
    font-size: 12px !important;
    font-weight: 400!important;
    padding: 2px 2px 2px 12px ;
    border-radius: 13px !important;
  }

  .status_ativo {
    font-size: 14px !important;
    background: #469c47;
    color: #fff;
    padding: 2px 12px;
    border-radius: 25px;
    height: 24px;
  }

  .status_pendente {
    font-size: 14px !important;
    background: goldenrod;
    color: #fff;
    padding: 2px 12px;
    border-radius: 25px;
    height: 24px;
  }

  .status_cancelado {
    font-size: 14px !important;
    background: #ff5252;
    color: #fff;
    padding: 2px 12px;
    border-radius: 25px;
    height: 24px;
  }

  .status_legend:hover {
    opacity: 0.5;
  }

  .status_legend_a_vencer {
    background: rgb(242, 192, 98);
  }

  .status_legend_vencidas {
    background: #FF5252;
  }

  .status_legend_pagos {
    background: #469c47;
  }

  .status_legend_selecionados {
    background: #6C87A2;
  }

  /*
  .status_aberto_dia_borda {
    border: 2px solid rgb(1, 168, 1) 
  }

  .status_aberto_atraso_borda {
    /* border: 2px solid rgb(1, 168, 1) 
  }
  */
  .status_a_vencer_atraso_color {
      color: red;
  }

  .status_baixado {
    background: #EF5350;
  }

  .status_pago-atraso {
    background: #FFA726;
  }

  .status_nao-encontrado {
    background: #7E57C2;
  }

  .status_msg-banco {
    background: #BDBDBD;
  }

  .status_invalido {
    background: #F44336;
  }

  .v-menu__content {
    z-index: 10!important;
  }
  /* table.v-table tbody td, table.v-table tbody th {
    height: 17px!important;
  } */

  .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
    font-size: 1.5rem !important;
  }
  
</style>
